import { useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { MetamaskActions, MetaMaskContext } from '../hooks';
import { connectSnap, getThemePreference, getSnap } from '../utils';
import { HeaderButtons } from './Buttons';
import { SnapLogo } from './SnapLogo';
import { Toggle } from './Toggle';
import { ReactComponent as MetaMaskFox } from '../assets/metamask_fox.svg';
import './Modal.css';
import gear from '../assets/gear-icon.png';
import shutdown from '../assets/shutdown.svg';
import MetamaskLogo from './MetamaskLogo';

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.default};
`;

const Notice = styled.div`
  background-color: ${({ theme }) => theme.colors.background.alternative};
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  color: ${({ theme }) => theme.colors.text.alternative};
  border-radius: ${({ theme }) => theme.radii.default};
  padding: 2rem;
  max-width: 60rem;
  text-align: center;
  width: 100%;

  & > * {
    margin: 0;
  }
  ${({ theme }) => theme.mediaQueries.small} {
    margin-top: 1.2rem;
    padding: 1.6rem;
  }
`;

const Title = styled.p`
  font-size: 15px;
  font-weight: bold;
  margin: 0;
  margin-left: 1.2rem;
  ${({ theme }) => theme.mediaQueries.small} {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Header = ({
  handleToggleClick,
  handleSettingsClick,
  handleLogoutClick,
  address,
}: {
  handleToggleClick(): void;
  handleSettingsClick(): void;
  handleLogoutClick(): void;
  address: string;
}) => {
  const theme = useTheme();
  const [state, dispatch] = useContext(MetaMaskContext);

  const handleConnectClick = async () => {
    try {
      await connectSnap();
      const installedSnap = await getSnap();

      dispatch({
        type: MetamaskActions.SetInstalled,
        payload: installedSnap,
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: MetamaskActions.SetError, payload: e });
    }
  };

  return (
    <HeaderWrapper>
      <LogoWrapper>
        <MetaMaskFox />
        <Title>{address}</Title>
      </LogoWrapper>
      <Notice>
        {' '}
        Double check that the URL is <b>https://tickets.metamask.io</b> before
        proceeding.
      </Notice>
      <RightContainer>
        {/* <Toggle
          onToggle={handleToggleClick}
          defaultChecked={getThemePreference()}
        /> */}
        <div className="settings-container" onClick={handleSettingsClick}>
          <div className="settings-button">
            <img src={gear} alt="" />
          </div>
          <span>Settings</span>
        </div>
        <div className="settings-container" onClick={handleLogoutClick}>
          <div className="settings-button">
            <img
              src={shutdown}
              alt=""
            />
          </div>
          <span>Logout</span>
        </div>
        <div>
          <HeaderButtons state={state} onConnectClick={handleConnectClick} />
        </div>
      </RightContainer>
    </HeaderWrapper>
  );
};
