import { SiweMessage } from 'siwe';
import { getAddress } from 'ethers/lib/utils';
import { defaultSnapOrigin } from '../config';
import { GetSnapsResponse, Snap } from '../types';
import { MetaMaskInpageProvider } from '@metamask/providers';
import pkg from "crypto-js";

const { SHA256 } = pkg;

/**
 * Get the installed snaps in MetaMask.
 *
 * @param provider - The MetaMask inpage provider.
 * @returns The snaps installed in MetaMask.
 */
export const getSnaps = async (
  provider?: MetaMaskInpageProvider,
): Promise<GetSnapsResponse> =>
  (await (provider ?? window.ethereum).request({
    method: 'wallet_getSnaps',
  })) as unknown as GetSnapsResponse;

/**
 * Connect a snap to MetaMask.
 *
 * @param snapId - The ID of the snap.
 * @param params - The params to pass with the snap to connect.
 */
export const connectSnap = async (
  snapId: string = defaultSnapOrigin,
  params: Record<'version' | string, unknown> = {},
) => {
  await window.ethereum.request({
    method: 'wallet_requestSnaps',
    params: {
      [snapId]: params,
    },
  });
};

/**
 * Get the snap from MetaMask.
 *
 * @param version - The version of the snap to install (optional).
 * @returns The snap object returned by the extension.
 */
export const getSnap = async (version?: string): Promise<Snap | undefined> => {
  try {
    const snaps = await getSnaps();

    return Object.values(snaps).find(
      (snap) =>
        snap.id === defaultSnapOrigin && (!version || snap.version === version),
    );
  } catch (e) {
    console.log('Failed to obtain installed snap', e);
    return undefined;
  }
};

const mockRetrieveApiKeyFromServer = async (
  chain: string,
  address: string,
  nonce: string,
  message: string,
  signature: string,
): Promise<string> => {
  console.log('Calling server to retrieve JWT...');

  let retVal: any;

  await fetch('https://71z6182pq3.execute-api.eu-west-1.amazonaws.com/default/signin', {
    body: JSON.stringify({
      name: chain,
      address: address,
      nonce,
      msg: message,
      sig: signature
      }),
    method: 'POST'
  })
  .then((response) => {
    console.log('Got JWT !');
    retVal = response.json();
    //console.log(retVal);
  })

  return retVal;

};


export const signInWithEthereum = async () => {
  const accounts = await window.ethereum.request<string[]>({
    method: 'eth_requestAccounts',
  });

  const account = accounts?.[0];
  if (!account) {
    throw new Error('Must accept wallet connection request.');
  }

  const address = getAddress(account);

  //get Nonce from SIWEbackend

  const res = await fetch(`https://71z6182pq3.execute-api.eu-west-1.amazonaws.com/default/signin?nonce`, {
    method: 'GET'
  });

  let nonce = await res.json();
  
  const statement = 'You are signing a plain-text message to prove you own this wallet address. No gas fees or transactions will occur.'
  const siweMessage = new SiweMessage({
    domain: window.location.hostname,
    uri: window.location.origin,
    version: '1',
    nonce: nonce,
    chainId: 1,
    address,
    statement,
  });

  const signature = await window.ethereum.request<string>({
    method: 'personal_sign',
    params: [siweMessage.prepareMessage(), address]
  });

  if (!signature) {
    throw new Error('Must accept sign-in with ethereum request.');
  }
  

  const sessionData = await mockRetrieveApiKeyFromServer(
    "1",
    address,
    nonce,
    siweMessage.toMessage(),
    signature,
  );


  //console.log("Returned session data: ", sessionData);
  let sessionObject = JSON.parse(sessionData);

  const apiKey = sessionObject.sessionId;
  const apiExpiry = new Date(sessionObject.expiryDate);

  // obfuscating the address header
  const address_key = SHA256(address, { outputLength: 32 }).toString();

  const resp = await fetch(`https://71z6182pq3.execute-api.eu-west-1.amazonaws.com/default/settings`, {
    method: 'GET',
    headers: {
      Uid: address_key,
      Authorization: apiKey,
      }
    });

  const dialog = JSON.stringify(await resp.json());

  console.log('Dialog from db', dialog);

  await window.ethereum.request({
    method: 'wallet_invokeSnap',
    params: {
      snapId: defaultSnapOrigin,
      request: { method: 'set_snap_state', params: { apiKey, address, dialog, apiExpiry } },
    },
  });

  return [apiKey, address, apiExpiry];
};



export const isLocalSnap = (snapId: string) => snapId.startsWith('local:');